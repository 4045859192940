import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// Reducers
import layoutReducer from '../Layout/redux/reducer';
import donationsReducer from '../apps/donations/redux/reducer';
import mapsReducer from '../apps/maps/redux/reducer';

const reducer = combineReducers({
  layout: layoutReducer,
  donations: donationsReducer,
  maps: mapsReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
