import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrees, faBars } from '@fortawesome/pro-light-svg-icons';

// Redux
import { layoutToggleSidebar } from './redux/actions';

const TopbarWrapper = styled.div`
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 9999;
  
  top: 0;
  padding: 1rem 0.5rem;
  text-transform: uppercase;
  
  h4 {
    margin-bottom: 0;
  }
`;

const MenuIcon = styled.div`
  font-size: 1.5rem;
  line-height: 1.25rem;
`;

class Topbar extends PureComponent {
  static propTypes = {
    // Actions
    layoutToggleSidebar: PropTypes.func.isRequired,
  };

  handleToggleSidebar = () => {
    const { layoutToggleSidebar: toggleSidebar } = this.props;
    toggleSidebar();
  };

  render() {
    return (
      <TopbarWrapper className="shadow">
        <Container>
          <div className="d-flex justify-content-between">
            <h4>
              <FontAwesomeIcon icon={faTrees} fixedWidth className="mr-1 text-primary" />
              replant
            </h4>
            <MenuIcon onClick={this.handleToggleSidebar}>
              <FontAwesomeIcon icon={faBars} fixedWidth />
            </MenuIcon>
          </div>
        </Container>
      </TopbarWrapper>
    );
  }
}

const mapDispatchToProps = {
  layoutToggleSidebar,
};

export default connect(null, mapDispatchToProps)(Topbar);
