import React from 'react';
import styled from 'styled-components';

// Components
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';

const CardElementWrapper = styled.div`
  margin-bottom: 1rem;
`;

const BaseCardElementStyled = styled.div`
  margin-bottom: 1rem;
  border-radius: 0.5rem;
`;

const CardNumberElementStyled = styled(BaseCardElementStyled)``;

const CardExpiryElementStyled = styled(BaseCardElementStyled)``;

const CardCVCElementStyled = styled(BaseCardElementStyled)`
  margin-bottom: 0;
`;

const CardElement = () => (
  <CardElementWrapper>
    <CardNumberElementStyled className="border-bottom shadow-sm p-3">
      <CardNumberElement style={{ base: { fontSize: '20px' } }} />
    </CardNumberElementStyled>
    <CardExpiryElementStyled className="border-bottom shadow-sm p-3">
      <CardExpiryElement style={{ base: { fontSize: '20px' } }} />
    </CardExpiryElementStyled>
    <CardCVCElementStyled className="border-bottom shadow-sm p-3">
      <CardCVCElement style={{ base: { fontSize: '20px' } }} />
    </CardCVCElementStyled>
  </CardElementWrapper>
);

export default CardElement;
