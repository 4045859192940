import { generateReducerElement as generate, initializeActions, reduxTypes as types } from '@tmosystems/core';

// Action creators
import { DONATIONS__CREATE_DONATION } from './actionCreators';

const initialState = {
  actions: initializeActions([
    DONATIONS__CREATE_DONATION,
  ]),
};

const reducer = (state = initialState, action) => ({
  ...generate(state, action, DONATIONS__CREATE_DONATION, { type: types.ASYNC }),
}[action.type] || state);

export default reducer;
