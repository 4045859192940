import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

// Redux
import { layoutToggleSidebar } from './redux/actions';

const SidebarWrapper = styled.div`
  position: absolute;
  width: 50%;
  height: 100vh;
  background-color: white;
  right: ${({ open }) => (open ? '0' : '-50%')};
  box-shadow: ${({ open }) => (open ? '0 1rem 3rem rgba(0, 0, 0, 0.175)' : 'none')};
  transition: right 400ms ease-in-out, box-shadow 400ms;
  z-index: 10000;
  font-size: 1.5rem;
`;

const SidebarContent = styled.div`
  padding: 1rem 0.5rem;
`;

const SidebarCloseIcon = styled.div`
  
`;

class Sidebar extends PureComponent {
  static propTypes = {
    // Actions
    layoutToggleSidebar: PropTypes.func.isRequired,

    // Layout
    sidebarOpen: PropTypes.bool.isRequired,
  };

  handleToggleSidebar = () => {
    const { layoutToggleSidebar: toggleSidebar } = this.props;
    toggleSidebar();
  };

  handleOnClick = () => {
    this.handleToggleSidebar();
  };

  render() {
    // Props
    const {
      // Layout
      sidebarOpen,
    } = this.props;

    return (
      <SidebarWrapper open={sidebarOpen}>
        <SidebarContent>
          <Container>
            <SidebarCloseIcon className="float-right" onClick={this.handleToggleSidebar}>
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </SidebarCloseIcon>
            <Link to="/" className="d-block text-black-50" onClick={this.handleOnClick}>
              Home
            </Link>
            <Link to="/donate" className="d-block text-black-50" onClick={this.handleOnClick}>
              Donate
            </Link>
            <Link to="/about" className="d-block text-black-50" onClick={this.handleOnClick}>
              About
            </Link>
            <Link to="/sources" className="d-block text-black-50" onClick={this.handleOnClick}>
              Sources
            </Link>
          </Container>
        </SidebarContent>
      </SidebarWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    sidebarOpen,
  } = state.layout;

  return {
    // Layout
    sidebarOpen,
  };
};

const mapDispatchToProps = {
  layoutToggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
