import React from 'react';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';
import Map from '../maps';

const HomeWrapper = styled.div`
  position: relative;
`;

const BottomOverlay = styled.div`
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 9999;
  bottom: 0;
`;

const DonateButton = styled.button`
  color: black;
  background-color: transparent;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  width: 100%;
  text-transform: uppercase;
`;

const Home = () => (
  <HomeWrapper>
    <Map />
    <BottomOverlay className="shadow">
      <Link to="/donate">
        <DonateButton>
          Plant a tree
        </DonateButton>
      </Link>
    </BottomOverlay>
  </HomeWrapper>
);

export default Home;
