import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

// Redux
import store from '../redux/store';

// Theme
import theme from '../styles/theme';

// Router
import Router from './Router';

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </StripeProvider>
    </ThemeProvider>
  </Provider>
);

export default hot(module)(App);
