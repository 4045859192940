import React, { Component } from 'react';
import styled from 'styled-components';

// Components
import { Container } from 'reactstrap';
import { Elements } from 'react-stripe-elements';
import PageWrapper from '../../shared/PageWrapper';
import PaymentForm from './PaymentForm';

// Images
import plant from '../../shared/gif/green.gif';
import plantOther from '../../shared/gif/source.gif';

const Plant = styled.img`
  width: 100%;
`;

const DonateAgain = styled.div``;

class Donations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donated: false,
      error: false,
    };
  }

  handleOnCreateDonationSuccess = () => {
    this.setState({
      donated: true,
      error: false,
    });
  };

  handleOnCreateDonationError = () => {
    this.setState({
      error: true,
    });
  };

  handleOnDonateAgain = () => {
    this.setState({
      donated: false,
    });
  };

  renderDonationPage = () => (
    <>
      <Plant src={plant} alt="" />
      <div className="mt-4">
        <h4>Donate to plant a tree</h4>
        <p>Your donation will go to TreeAid. They will plant a tree for every pound you donate.</p>
        <p>Use the official Stripe payment form below to donate</p>
        <Elements>
          <PaymentForm
            // Callbacks
            onSuccess={this.handleOnCreateDonationSuccess}
            onError={this.handleOnCreateDonationError}
          />
        </Elements>
        <div className="text-center mt-3">
          <small>
            This app is fully PCI compliant, none of your details ever pass through our server.
          </small>
        </div>
      </div>
    </>
  );

  renderDonatedPage = () => (
    <div className="text-center">
      <Plant src={plantOther} alt="" />
      <h1>Thank you</h1>
      <p>
        Your contribution will make a world of difference.
      </p>
      <DonateAgain onClick={this.handleOnDonateAgain}>
        <h1>Donate again</h1>
      </DonateAgain>
    </div>
  );

  render() {
    // State
    const {
      donated,
      error,
    } = this.state;

    return (
      <PageWrapper className="pt-1 pb-5">
        <Container>
          {error && (
            <h1 className="text-danger text-center p-3">
              There was an error.
            </h1>
          )}
          {donated
            ? this.renderDonatedPage()
            : this.renderDonationPage()}
        </Container>
      </PageWrapper>
    );
  }
}

export default Donations;
