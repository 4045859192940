import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ReactMapGL from 'react-map-gl';

// Actions
import { mapsChangeViewport, mapsChangeViewportIndex } from './redux/actions';

class Map extends Component {
  static propTypes = {
    // Actions
    mapsChangeViewport: PropTypes.func.isRequired,
    mapsChangeViewportIndex: PropTypes.func.isRequired,

    // Maps
    viewport: PropTypes.instanceOf(Object).isRequired,
    viewportIndex: PropTypes.number.isRequired,
    viewports: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      interval: null,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(this.handleNextViewport, 5000),
    });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  handleOnChangeViewport = (viewport) => {
    const { mapsChangeViewport: changeViewport } = this.props;
    changeViewport({ viewport });
  };

  handleNextViewport = () => {
    const { interval } = this.state;
    const {
      mapsChangeViewport: changeViewport,
      mapsChangeViewportIndex: changeViewportIndex,
      viewportIndex,
      viewports,
    } = this.props;
    const newViewportIndex = viewportIndex + 1;
    if (newViewportIndex === viewports.length) {
      clearInterval(interval);
    } else {
      changeViewport({ viewport: viewports[newViewportIndex] });
      changeViewportIndex({ viewportIndex: newViewportIndex });
    }
  };

  render() {
    // Props
    const {
      // Maps
      viewport,
    } = this.props;

    return (
      <div>
        <ReactMapGL
          {...viewport}
          width="100%"
          height="100vh"
          mapStyle="mapbox://styles/replantearth/ck6omjtgl35921ilerggxtv10"

          // Callbacks
          onViewportChange={this.handleOnChangeViewport}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    viewport,
    viewportIndex,
    viewports,
  } = state.maps;

  return {
    // Maps
    viewport,
    viewportIndex,
    viewports,
  };
};

const mapDispatchToProps = {
  mapsChangeViewport,
  mapsChangeViewportIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
