import { create } from 'axios';
import { createAxiosInstance } from '@tmosystems/core';

const axios = createAxiosInstance(create, {
  baseURL: '/donations',
});

// POST
const createDonation = (requestData) => axios.post('/', requestData);

export default {
  POST: {
    donation: createDonation,
  },
};
