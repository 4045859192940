import { generateReducerElement as generate, reduxTypes as types } from '@tmosystems/core';
import { FlyToInterpolator, LinearInterpolator } from 'react-map-gl';
import { easeCubic } from 'd3-ease';

// Action creators
import {
  MAPS__CHANGE_VIEWPORT,
  MAPS__CHANGE_VIEWPORT_INDEX,
} from './actionCreators';

const initialState = {
  viewport: {
    latitude: -2.163106,
    longitude: -55.126648,
    zoom: 1,
  },
  viewportIndex: 0,
  viewports: [
    {
      latitude: -2.163106,
      longitude: -55.126648,
      zoom: 1,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    },
    {
      latitude: -2.163106,
      longitude: -55.126648,
      zoom: 7,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    },
    {
      latitude: 4.2281945756256665,
      longitude: -61.05142607165712,
      zoom: 7,
      pitch: 60,
      transitionDuration: 6000,
      transitionInterpolator: new LinearInterpolator(),
      transitionEasing: easeCubic,
    },
    {
      latitude: 4.2281945756256665,
      longitude: -61.05142607165712,
      zoom: 7,
      pitch: 60,
      bearing: 180,
      transitionDuration: 6000,
      transitionInterpolator: new LinearInterpolator(),
      transitionEasing: easeCubic,
    },
    {
      latitude: -26.74497406839797,
      longitude: -66.20389042639646,
      zoom: 2,
      pitch: 0,
      bearing: 0,
      transitionDuration: 6000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    },
  ],
};

const reducer = (state = initialState, action) => ({
  ...generate(state, action, MAPS__CHANGE_VIEWPORT, {
    type: types.SYNC,
    adjustment: {
      viewport: action.viewport,
    },
  }),

  ...generate(state, action, MAPS__CHANGE_VIEWPORT_INDEX, {
    type: types.SYNC,
    adjustment: {
      viewportIndex: action.viewportIndex,
    },
  }),
}[action.type] || state);

export default reducer;
