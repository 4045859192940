import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

// Components
import Topbar from '../Layout/Topbar';
import Sidebar from '../Layout/Sidebar';
import PageContent from '../Layout/PageContent';
import Home from '../apps/home';
import DonationsIndex from '../apps/donations';
import Maps from '../apps/maps';

const PageWrapper = styled.div`
  position: relative;
  max-width: 100vw;
  overflow: hidden;
`;

const DonationsRoutes = () => (
  <>
    <Route exact path="/donate" component={DonationsIndex} />
  </>
);

const MapsRoutes = () => (
  <>
    <Route exact="/maps" component={Maps} />
  </>
);

const WrappedRoutes = () => (
  <PageWrapper>
    <Topbar />
    <Sidebar />
    <PageContent>
      <Route exact path="/" component={Home} />
      <Route exact path="/donate" component={DonationsRoutes} />
      <Route exact path="/maps" component={MapsRoutes} />
    </PageContent>
  </PageWrapper>
);

const Router = () => (
  <main>
    <Switch>
      <Route path="/" component={WrappedRoutes} />
    </Switch>
  </main>
);

export default Router;
