import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import axios from 'axios';
import { wrapper } from '@tmosystems/core';
import styled from 'styled-components';

// Components
import CardElement from './CardElement';
import AsyncButton from '../../shared/components/AsyncButton';

// Redux
import { donationsCreateDonation } from './redux/actions';
import { DONATIONS__CREATE_DONATION } from './redux/actionCreators';

const PaymentFormWrapper = styled.div`
  button {
    color: green;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.color.primary};
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    width: 100%;
  }
`;

const DonationChoice = styled.div`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.color.primary};
  padding: 0.2rem;
  border-radius: 0.25rem;
  background-color: ${({ theme, selected }) => (selected ? theme.color.primary : 'transparent')};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
`;

class PaymentForm extends Component {
  static propTypes = {
    // Actions
    donationsCreateDonation: PropTypes.func.isRequired,

    // Donations
    donationsActions: PropTypes.instanceOf(Object).isRequired,

    // Stripe
    stripe: PropTypes.instanceOf(Object).isRequired,

    // Callbacks
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      donationChoice: 1,
    };
  }

  handleOnSubmit = (event) => {
    event.preventDefault();
    const { donationChoice } = this.state;
    const {
      stripe, donationsCreateDonation: createDonation, onSuccess, onError,
    } = this.props;
    // We need a way to uniquely identify a donor. To do
    // this we will use CloudFare to get the user's IP,
    // hash it and use it to identify a donor.
    const traceResponse = axios.get(process.env.REACT_APP_IP_FETCH_URL);
    stripe.createToken({ type: 'card', name: traceResponse.query })
      .then((result) => {
        if (result.token) {
          // Token was created successfully. We need to send
          // this the server to create the charge.
          createDonation({ token: result.token, trace: traceResponse, amount: donationChoice })
            .then((action) => {
              if (action.type === DONATIONS__CREATE_DONATION.SUCCESS) {
                onSuccess();
              }
              if (action.type === DONATIONS__CREATE_DONATION.ERROR) {
                onError();
              }
            });
        }
        if (result.error) {
          // There was an error creating the token.
        }
      });
  };

  handleDonationChoiceOnClick = (event) => {
    const { id } = event.currentTarget.dataset;
    this.setState({
      donationChoice: parseInt(id, 10),
    });
  };

  renderDonationChoices = () => {
    // State
    const {
      donationChoice,
    } = this.state;

    return (
      <div className="d-flex text-center mb-3">
        <DonationChoice
          onClick={this.handleDonationChoiceOnClick}
          data-id={1}
          selected={donationChoice === 1}
        >
          £1
        </DonationChoice>
        <DonationChoice
          className="mx-4"
          onClick={this.handleDonationChoiceOnClick}
          data-id={2}
          selected={donationChoice === 2}
        >
          £2
        </DonationChoice>
        <DonationChoice
          onClick={this.handleDonationChoiceOnClick}
          data-id={5}
          selected={donationChoice === 5}
        >
          £5
        </DonationChoice>
      </div>
    );
  };

  render() {
    // Props
    const {
      // Donations
      donationsActions: {
        DONATIONS__CREATE_DONATION: createDonationAction,
      },
    } = this.props;

    return (
      <PaymentFormWrapper>
        <form onSubmit={this.handleOnSubmit}>
          {this.renderDonationChoices()}
          <CardElement />
          <AsyncButton action={createDonationAction} type="submit">Donate</AsyncButton>
        </form>
      </PaymentFormWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    actions: donationsActions,
  } = state.donations;

  return {
    // Donations
    donationsActions,
  };
};

const mapDispatchToProps = {
  donationsCreateDonation,
};

export default wrapper({
  component: PaymentForm,
  wrappers: [
    injectStripe,
    connect(mapStateToProps, mapDispatchToProps),
  ],
});
