import { generateReducerElement as generate, reduxTypes as types } from '@tmosystems/core';

// Action creators
import { LAYOUT__TOGGLE_SIDEBAR } from './actionCreators';

const initialState = {
  sidebarOpen: false,
};

const reducer = (state = initialState, action) => ({
  ...generate(state, action, LAYOUT__TOGGLE_SIDEBAR, {
    type: types.SYNC,
    adjustment: {
      sidebarOpen: !state.sidebarOpen,
    },
  }),
}[action.type] || state);

export default reducer;
