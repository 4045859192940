import { generateAction as generate, reduxTypes as types } from '@tmosystems/core';
import service from './service';

// Action creators
import { DONATIONS__CREATE_DONATION } from './actionCreators';

export const donationsCreateDonation = generate(DONATIONS__CREATE_DONATION, {
  type: types.ASYNC,
  service: service.POST.donation,
});
